import React, { useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { formatEther, parseEther, zeroAddress } from 'viem';
import { 
    useContract, 
    useContractRead, 
    useContractWrite, 
    useSwitchChain, 
    useChain, 
    useChainId } from "@thirdweb-dev/react";

const Admin = ({connected, SweetA}) => {
    const switchChain = useSwitchChain();

    const chain = useChain();

    const chainId = useChainId();
    
    const [ connectedChain, setConnectedChain ] = useState(null)

    const [ usdtToken, setUsdtToken ]       = useState({});
    const [ coinsTrail, setCoinsTrail ]     = useState({});
    const [ multisig, setMultiSig ]         = useState({});
    const [ teamMultisig, setTeamMultiSig ] = useState({});

    // MultiSig 
    const [ destination, setDestination ]   = useState(zeroAddress);
    const [ tokenAddress, setTokenAddress]  = useState(zeroAddress);
    const [ selected, setSelected ]         = useState();
    const [ isToken, setIsToken ]           = useState(false);
    const [ rqtAmount, setRqtAmount ]       = useState("0");
    // Rqts Count
    const [ admnRqts, setAdmnRqts ]         = useState(0);
    const [ mktgRqts, setMktgRqts ]         = useState(0);
    const [ teamRqts, setTeamRqts ]         = useState(0);
    // pendings
    const [ pdgAdmn, setPdgAdmn ]           = useState([]); // all pendings
    const [ pdgMktg, setPdgMktg ]           = useState([]); // all pendings
    const [ pdgTeam, setPdgTeam ]           = useState([]); // all pendings
    // {_by: zeroAddress, _amount : 0, _vldtions: 0, _to: zeroAddress, _exctd: false}

    const { contract: systemReserve }       = useContract(
        multisig.address1,
        multisig.abi,
    );

    const { contract: marketingWallet }    = useContract(
        multisig.address2,
        multisig.abi,
    );

    const { contract: teamContract }       = useContract(
        teamMultisig.address,
        teamMultisig.abi,
    );

    const { contract: ctrlContract }       = useContract(
        coinsTrail.address,
        coinsTrail.abi,
    );

    const { contract: usdtContract }       = useContract(
        usdtToken.address,
        usdtToken.abi,
    );

    // change network
    const changeNetwork = async () => {
        try{
            await switchChain(56);
        }catch (e) {}
    }

    useEffect(() => {
        if(chain) {
            setConnectedChain({
                name: chainId === 56 ? chain.name : 'Wrong Network!',
                id: chainId
            })
        };
    }, [chain, chainId])


    useEffect(() => {
        fetch('MultiSigWallet.json').then(response => {
            response.json().then(contract => {
                setMultiSig(contract);
            });
        });
        fetch('TeamWallet.json').then(response => {
            response.json().then(contract => {
                setTeamMultiSig(contract);
            });
        });
        fetch('CoinsTrail.json').then(response => {
            response.json().then(contract => {
                setCoinsTrail(contract);
            });
        });
        fetch('USDTToken.json').then(response => {
            response.json().then(contract => {
                setUsdtToken(contract);
            });
        });
    }, []);
    
    /**
     * ToDo
     * 1- List Pending Reqst
     * 2- Approve Request
     * 3- Make New Reqst
     * 4- view balances for 3 contracts [ctrl & usdt]
     *  */ 

    // CTRL balance
    const { data: admnCTRLBlc }  = useContractRead( ctrlContract, "balanceOf", [multisig.address1]);
    const { data: mrktCTRLBlc }  = useContractRead( ctrlContract, "balanceOf", [multisig.address2]);
    const { data: teamCTRLBlc }  = useContractRead( ctrlContract, "balanceOf", [teamMultisig.address]);
    // USDT balance
    const { data: admnUSDTBlc }  = useContractRead( usdtContract, "balanceOf", [multisig.address1]);
    const { data: mrktUSDTBlc }  = useContractRead( usdtContract, "balanceOf", [multisig.address2]);
    const { data: teamUSDTBlc }  = useContractRead( usdtContract, "balanceOf", [teamMultisig.address]);
    // Pendings
    const { data: _admnRqts } = useContractRead( systemReserve, "_lastTransaction");
    const { data: _mktgRqts }  = useContractRead( marketingWallet, "_lastTransaction");
    const { data: _teamRqts }  = useContractRead( teamContract, "_lastTransaction");
    // Total Requests
    useEffect(() => {
        if(_admnRqts !== admnRqts)
            setAdmnRqts(_admnRqts);
    }, [_admnRqts]);

    useEffect(() => {
        if(_mktgRqts !== mktgRqts)
            setMktgRqts(_mktgRqts);
    }, [_mktgRqts]);

    useEffect(() => {
        if(_teamRqts !== teamRqts)
            setTeamRqts(_teamRqts);
    }, [_teamRqts]);
    
    // list only pending requests
    useEffect(() => {
        if(admnRqts > 1){
            for(let i = 1; i <= admnRqts; i++){
                // call pending and record here
            }
        }
    }, [admnRqts]);
    // verify if user has already approved
    // verify if request already have enough approval and show process button

    // send ADMIN WLT request
    // send MARKETING request
    // send TEAM WALL request

    return(
        <section id="about" className="overflow-hidden py-12">
            <div className="container flex flex-col items-center gap-16 px-4 lg:px-0">
                <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] 
                            to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl"
                            style={{textTransform: 'uppercase'}}>
                            MULTISIG WALLETS </h4>
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="w-full lg:w-1/3" style={{opacity: 1, transform: 'none'}}>
                        <div className="relative flex w-full justify-center lg:w-1" style={{opacity: 1, transform: 'none'}}>
                            <div className="relative  w-full max-w-lg overflow-hidden rounded-3xl bg-[#1B0432]/80 backdrop-blur-xl">
                                {!connected && <div className="absolute inset-0 z-20 flex h-full w-full 
                                items-center justify-center bg-black/60 backdrop-blur-sm" 
                                    role="status">
                                <svg aria-hidden="true" class="h-8 w-8 animate-spin fill-primary text-gray-200" 
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>}
                            <div className="mb-6 flex flex-col gap-6 px-5 pt-8">
                                <h4 className="bg-gradient-to-b from-[#F320D8] to-[#16A6EE] 
                                    bg-clip-text text-center text-3xl font-medium 
                                    text-transparent lg:text-4xl">ADMIN RESERVE</h4>
                                <div className="text-center">
                                    CTRL BALANCE: 0<br />
                                    USDT BALANCE: 0<br />
                                </div>
                                {connectedChain && <div className="flex flex-col items-center">
                                    <button 
                                        className="flex gap-2 rounded-lg border 
                                        border-white/10 bg-transparent py-2 px-4 
                                        text-center font-semibold lg:py-4"
                                        onClick={async () => {
                                            await changeNetwork();
                                        }}
                                        >
                                        {chainId === 56 && <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                                            <g clip-path="url(#clip0_7_306)">
                                                <path d="M8.56214 11.7658L13.9999 6.32797L19.4403 11.7681L22.6043 8.60414L13.9999 0L5.39838 8.60156L8.56225 11.7656M0 13.9999L3.1641 10.8354L6.32797 13.9993L3.16387 17.1634L0 13.9999ZM8.56214 16.2344L13.9999 21.6719L19.4402 16.2319L22.6059 19.3942L22.6043 19.3959L13.9999 27.9999L5.39838 19.3983L5.3939 19.3938L8.56248 16.2341M21.6719 14.0013L24.836 10.8372L27.9999 14.0011L24.8359 17.1652L21.6719 14.0013Z" fill="#F3BA2F"></path>
                                                <path d="M17.2089 13.9983H17.2102L13.9999 10.7877L11.6269 13.16L11.3543 13.4327L10.7921 13.995L10.7876 13.9994L10.7921 14.004L13.9999 17.2122L17.2104 14.0016L17.212 13.9998L17.2091 13.9983" fill="#F3BA2F"></path>
                                            </g>
                                            <defs>
                                                <clippath id="clip0_7_306">
                                                    <rect width="28" height="27.9999" fill="white"></rect>
                                                </clippath>
                                            </defs>
                                        </svg>}
                                        <span className="truncate">{ connectedChain.name }</span>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                            className="h-3 w-3" style={{position: "relative", top: "5px"}}>
                                            <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>}
                                <div className="-mx-5 h-[2px] bg-[#30353A]"></div>
                                <div className='text-center'>
                                    <h5>Request Transaction</h5>
                                </div>
                                <div className="flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20 ">
                                    <div className="flex flex-1 flex-col gap-1">
                                        <label className="text-sm">Enter Amount</label>
                                        <input className="w-full bg-transparent text-xl outline-none" 
                                            type="text" placeholder={zeroAddress} 
                                            value={ rqtAmount }
                                            onChange={e => { setRqtAmount(e.target.value) } } />
                                    </div>
                                </div>
                                <div className="flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20">
                                    <div className="flex flex-1 flex-col gap-1">
                                        <label className="text-sm">To [Enter Address]</label>
                                        <input className="w-full bg-transparent text-xs outline-none" 
                                            type="text"
                                            placeholder={zeroAddress}
                                            value={ destination } 
                                            onChange={e => { setDestination(e.target.value) }} />
                                    </div>
                                </div>
                                    <span> Parameters, Please select 1</span>
                                <div className="flex rounded-xl border-2 border-transparent 
                                        bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent 
                                        focus-within:border-primary/50 focus-within:ring-primary/20">
                                    <div className="flex flex-1 gap-1">
                                        <input id="ctrl" value="CTRL" 
                                                checked={selected === "CTRL"}
                                                onChange={e => {setSelected(e.target.value)}}
                                                className="bg-transparent 
                                            text-xs outline-none" type="checkbox"/>
                                        <label for="ctrl">Is CTRL ?</label> 
                                        <input id='usdt' value="USDT"
                                                checked={selected === "USDT"}
                                                onChange={e => {setSelected(e.target.value)}}
                                                className="bg-transparent 
                                            text-xs outline-none" type="checkbox"/>
                                        <label for="usdt">Is USDT ?</label>
                                    </div>
                                </div>
                                <div className="-mx-5 h-[2px] bg-[#30353A]"></div>
                                <button className="text-normal 
                                        relative flex w-full items-center 
                                        justify-center gap-2 rounded-xl 
                                        bg-gradient-to-tr from-[#16A6EE] 
                                        to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                        hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl" 
                                        type="button" >
                                     SEND TRANSACTION
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3" style={{opacity: 1, transform: 'none'}}>
                        <div className="relative flex w-full justify-center lg:w-1" style={{opacity: 1, transform: 'none'}}>
                            <div className="relative  w-full max-w-lg overflow-hidden rounded-3xl bg-[#1B0432]/80 backdrop-blur-xl">
                                {!connected && <div className="absolute inset-0 z-20 flex h-full w-full 
                                items-center justify-center bg-black/60 backdrop-blur-sm" 
                                    role="status">
                                <svg aria-hidden="true" class="h-8 w-8 animate-spin fill-primary text-gray-200" 
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>}
                            <div className="mb-6 flex flex-col gap-6 px-5 pt-8">
                                <h4 className="bg-gradient-to-b from-[#F320D8] to-[#16A6EE] 
                                    bg-clip-text text-center text-3xl font-medium 
                                    text-transparent lg:text-4xl">MARKETING WALLET</h4>
                                <div className="text-center">
                                    CTRL BALANCE: 0<br />
                                    USDT BALANCE: 0<br />
                                </div>
                                {connectedChain && <div className="flex flex-col items-center">
                                    <button 
                                        className="flex gap-2 rounded-lg border 
                                        border-white/10 bg-transparent py-2 px-4 
                                        text-center font-semibold lg:py-4"
                                        onClick={async () => {
                                            await changeNetwork();
                                        }}
                                        >
                                        {chainId === 56 && <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                                            <g clip-path="url(#clip0_7_306)">
                                                <path d="M8.56214 11.7658L13.9999 6.32797L19.4403 11.7681L22.6043 8.60414L13.9999 0L5.39838 8.60156L8.56225 11.7656M0 13.9999L3.1641 10.8354L6.32797 13.9993L3.16387 17.1634L0 13.9999ZM8.56214 16.2344L13.9999 21.6719L19.4402 16.2319L22.6059 19.3942L22.6043 19.3959L13.9999 27.9999L5.39838 19.3983L5.3939 19.3938L8.56248 16.2341M21.6719 14.0013L24.836 10.8372L27.9999 14.0011L24.8359 17.1652L21.6719 14.0013Z" fill="#F3BA2F"></path>
                                                <path d="M17.2089 13.9983H17.2102L13.9999 10.7877L11.6269 13.16L11.3543 13.4327L10.7921 13.995L10.7876 13.9994L10.7921 14.004L13.9999 17.2122L17.2104 14.0016L17.212 13.9998L17.2091 13.9983" fill="#F3BA2F"></path>
                                            </g>
                                            <defs>
                                                <clippath id="clip0_7_306">
                                                    <rect width="28" height="27.9999" fill="white"></rect>
                                                </clippath>
                                            </defs>
                                        </svg>}
                                        <span className="truncate">{ connectedChain.name }</span>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                            className="h-3 w-3" style={{position: "relative", top: "5px"}}>
                                            <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>}
                                <div className="-mx-5 h-[2px] bg-[#30353A]"></div>
                                <div className='text-center'>
                                    <h5>Request Transaction</h5>
                                </div>
                                <div className="flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20 ">
                                    <div className="flex flex-1 flex-col gap-1">
                                        <label className="text-sm">Enter Amount</label>
                                        <input className="w-full bg-transparent text-xl outline-none" 
                                            type="text" placeholder={zeroAddress} 
                                            value={ rqtAmount }
                                            onChange={e => { setRqtAmount(e.target.value) } } />
                                    </div>
                                </div>
                                <div className="flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20">
                                    <div className="flex flex-1 flex-col gap-1">
                                        <label className="text-sm">To [Enter Address]</label>
                                        <input className="w-full bg-transparent text-xs outline-none" 
                                            type="text"
                                            placeholder={zeroAddress}
                                            value={ destination } 
                                            onChange={e => { setDestination(e.target.value) }} />
                                    </div>
                                </div>
                                <span> Parameters, Please select 1</span>
                                <div className="flex rounded-xl border-2 border-transparent 
                                        bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent 
                                        focus-within:border-primary/50 focus-within:ring-primary/20">
                                    <div className="flex flex-1 gap-1">
                                        <input id="ctrl" value="CTRL" 
                                                checked={selected === "CTRL"}
                                                onChange={e => {setSelected(e.target.value)}}
                                                className="bg-transparent 
                                            text-xs outline-none" type="checkbox"/>
                                        <label for="ctrl">Is CTRL ?</label> 
                                        <input id='usdt' value="USDT"
                                                checked={selected === "USDT"}
                                                onChange={e => {setSelected(e.target.value)}}
                                                className="bg-transparent 
                                            text-xs outline-none" type="checkbox"/>
                                        <label for="usdt">Is USDT ?</label>
                                    </div>
                                </div>
                                <div className="-mx-5 h-[2px] bg-[#30353A]"></div>
                                <button className="text-normal 
                                        relative flex w-full items-center 
                                        justify-center gap-2 rounded-xl 
                                        bg-gradient-to-tr from-[#16A6EE] 
                                        to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                        hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl" 
                                        type="button" >
                                     SEND TRANSACTION
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3" style={{opacity: 1, transform: 'none'}}>
                        <div className="relative flex w-full justify-center lg:w-1" style={{opacity: 1, transform: 'none'}}>
                            <div className="relative  w-full max-w-lg overflow-hidden rounded-3xl bg-[#1B0432]/80 backdrop-blur-xl">
                                {!connected && <div className="absolute inset-0 z-20 flex h-full w-full 
                                items-center justify-center bg-black/60 backdrop-blur-sm" 
                                    role="status">
                                <svg aria-hidden="true" class="h-8 w-8 animate-spin fill-primary text-gray-200" 
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>}
                            <div className="mb-6 flex flex-col gap-6 px-5 pt-8">
                                <h4 className="bg-gradient-to-b from-[#F320D8] to-[#16A6EE] 
                                    bg-clip-text text-center text-3xl font-medium 
                                    text-transparent lg:text-4xl">TEAM WALLET</h4>
                                
                                <div className="text-center">
                                    USDT BALANCE: 0<br />
                                    CTRL BALANCE: 0<br />
                                </div>
                                
                                {connectedChain && <div className="flex flex-col items-center">
                                    <button 
                                        className="flex gap-2 rounded-lg border 
                                        border-white/10 bg-transparent py-2 px-4 
                                        text-center font-semibold lg:py-4"
                                        onClick={async () => {
                                            await changeNetwork();
                                        }}
                                        >
                                        {chainId === 56 && <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                                            <g clip-path="url(#clip0_7_306)">
                                                <path d="M8.56214 11.7658L13.9999 6.32797L19.4403 11.7681L22.6043 8.60414L13.9999 0L5.39838 8.60156L8.56225 11.7656M0 13.9999L3.1641 10.8354L6.32797 13.9993L3.16387 17.1634L0 13.9999ZM8.56214 16.2344L13.9999 21.6719L19.4402 16.2319L22.6059 19.3942L22.6043 19.3959L13.9999 27.9999L5.39838 19.3983L5.3939 19.3938L8.56248 16.2341M21.6719 14.0013L24.836 10.8372L27.9999 14.0011L24.8359 17.1652L21.6719 14.0013Z" fill="#F3BA2F"></path>
                                                <path d="M17.2089 13.9983H17.2102L13.9999 10.7877L11.6269 13.16L11.3543 13.4327L10.7921 13.995L10.7876 13.9994L10.7921 14.004L13.9999 17.2122L17.2104 14.0016L17.212 13.9998L17.2091 13.9983" fill="#F3BA2F"></path>
                                            </g>
                                            <defs>
                                                <clippath id="clip0_7_306">
                                                    <rect width="28" height="27.9999" fill="white"></rect>
                                                </clippath>
                                            </defs>
                                        </svg>}
                                        <span className="truncate">{ connectedChain.name }</span>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                            className="h-3 w-3" style={{position: "relative", top: "5px"}}>
                                            <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>}
                                <span> Parameters, Please select 1</span>
                                <div className="flex rounded-xl border-2 border-transparent 
                                        bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent 
                                        focus-within:border-primary/50 focus-within:ring-primary/20">
                                    <div className="flex flex-1 gap-1">
                                        <input id="ctrl" value="CTRL" 
                                                checked={selected === "CTRL"}
                                                onChange={e => {setSelected(e.target.value)}}
                                                className="bg-transparent 
                                            text-xs outline-none" type="checkbox"/>
                                        <label for="ctrl"> Is CTRL ?</label> 
                                        <input id='usdt' value="USDT"
                                                checked={selected === "USDT"}
                                                onChange={e => {setSelected(e.target.value)}}
                                                className="bg-transparent 
                                            text-xs outline-none" type="checkbox"/>
                                        <label for="usdt"> Is USDT ?</label>
                                    </div>
                                </div>
                                <div className="-mx-5 h-[2px] bg-[#30353A]"></div>
                                <button className="text-normal 
                                        relative flex w-full items-center 
                                        justify-center gap-2 rounded-xl 
                                        bg-gradient-to-tr from-[#16A6EE] 
                                        to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                        hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl" 
                                        type="button" >
                                    SEND TRANSACTION
                                </button>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Admin;
import about from '../img/about-0597b00b.png'
import mission from '../img/mission-072a60a7.png'
import vision from '../img/feature2.png'

const About = () => {
    return(
        <section id="about" className="overflow-hidden py-12">
            <div className="container flex flex-col items-center gap-16 px-4 lg:px-0">
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="order-last w-full lg:order-first lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <img src={about} alt="about-img" className="h-auto max-w-full" />
                    </div>
                    <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] 
                            to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl"
                            style={{textTransform: 'uppercase'}}>
                            Innovative Web3-Powered Ecosystem</h4>
                        <p className="mb-6">
                            Welcome to COINSTRAIL, where innovation meets the future of 
                            cryptocurrency adoption. Our platform is a trailblazing force 
                            in the blockchain space, redefining how you advertise, monetize, 
                            and engage with cryptocurrencies. 
                        </p>
                        <p>
                            Built on the robust foundation of blockchain technology, COINSTRAIL 
                            ensures a secure, transparent, and efficient environment for all your crypto needs.
                        </p>
                        <br />
                        <p>
                            With a sleek, user-friendly interface and a suite of powerful features, 
                            we provide you with the tools to effortlessly reach your target audience. 
                            Join our thriving community of advertisers and users, and elevate your brand to 
                            unprecedented heights.
                        </p>
                        <br />
                        <p>
                            COINSTRAIL isn't just a platform; it's the future of advertising, 
                            monetization, and crypto engagement, all in one place. 
                            Welcome to the trail that leads to crypto success with COINSTRAIL.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl">MISSION</h4>
                        <p className="rounded-xl bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] p-3">
                            COINSTRAIL's mission is to bridge the gap between Traditional PTC and the DeFi industry, 
                            offering advertisers enhanced results from their ad budgets, all while providing secure, 
                            reliable, and transparent income opportunities. We're here to revolutionize the way you advertise, 
                            earn, and engage with the crypto ecosystem.
                        </p>
                        <p className="p-3">
                            We offer an ecosystem designed for sustainable passive income through Crypto-Powered PTC, 
                            Crypto Currency Literacy, E-commerce, and a robust tokenomics model. 
                            Join us in reshaping the future of cryptocurrency adoption and sustainable income generation.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2 " style={{opacity: 1, transform: 'none'}}>
                        <img src={mission} 
                        alt="about-img" className="h-auto max-w-full" />
                    </div>
                </div>
                <div className="flex flex-col items-center gap-16 lg:flex-row">
                    <div className="order-last w-full lg:order-first lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <img src={ vision }
                            alt="about-img" className="h-auto max-w-full" />
                    </div>
                    <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
                        <h4 className="mb-6 bg-gradient-to-b from-[#F320D8] to-[#16A6EE] bg-clip-text text-3xl font-medium leading-snug text-transparent lg:text-5xl">VISION</h4>
                        <p className="">
                            At COINSTRAIL, our vision is to be the driving force behind the transformation of DeFi. 
                            We're dedicated to providing advertisers with cutting-edge blockchain technology for safer, 
                            trusted, and smarter advertising. Our mission is to deliver genuine value by placing advertisers 
                            directly in front of potential clients. We aim to create a dynamic ecosystem that offers 
                            limitless potential for growth and prosperity in the world of cryptocurrency adoption.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
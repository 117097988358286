import bnbLogo from "../img/bnb.webp";
import usdtLogo from "../img/usdt.webp";

const Payoption = ({ modalShow, setModalShow, payOption, setPayOption}) => {
    // Function to close the modal when clicked outside

    // useEffect(() => {
    //     setModalShow(!modalShow);
    // }, [payOption]);

    return(
        <>{modalShow && 
            <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-[#131212]/70 px-4 lg:px-0">
                <div  id='payment-option-container' className="w-full max-w-xl rounded-3xl border 
                    border-white/10 bg-[#1A2025]/70 p-6 backdrop-blur-xl lg:p-8">
                    <div className="mb-2.5 flex items-center justify-between">
                        <h4 className="text-2xl font-medium uppercase">Select Token</h4>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" 
                            className="cursor-pointer transition-opacity duration-200 hover:opacity-75" onClick={() => {setModalShow(false)}}>
                                <rect x="12.728" width="2" height="18" rx="1" transform="rotate(45 12.728 0)" fill="currentColor"></rect>
                                <rect y="1.41422" width="2" height="18" rx="1" transform="rotate(-45 0 1.41422)" fill="currentColor"></rect>
                        </svg>
                    </div>
                    <ul className="mx-auto max-h-[80vh] max-w-full overflow-y-auto rounded-2xl bg-[#2F3B4F]/50">
                        <li className="flex cursor-pointer items-center gap-4 
                            rounded-xl py-2.5 px-4 transition-all duration-200 
                            hover:bg-primary/50" onClick={() => { payOption !== 'bnb' && setPayOption('bnb'); setModalShow(false) }}>
                            <img src={bnbLogo} alt="BNB logo" width="40" height="40" className="h-10 w-10 rounded-full bg-white p-1" loading="lazy" />
                            <p className="flex flex-col font-medium">BNB<span className="text-xs font-light text-slate-500 dark:text-slate-200">BNB Smart Chain (BEP20)</span>
                            </p>
                        </li>
                        <li className="flex cursor-pointer items-center gap-4 r
                            ounded-xl py-2.5 px-4 transition-all duration-200 
                            hover:bg-primary/50" onClick={() => { payOption !== 'usdt' && setPayOption('usdt'); setModalShow(false) }}>
                            <img src={usdtLogo} alt="USDT logo" width="40" height="40" className="h-10 w-10 rounded-full bg-white p-1" loading="lazy" />
                            <p className="flex flex-col font-medium">USDT<span className="text-xs font-light text-slate-500 dark:text-slate-200">Binance-Peg USD (BEP20)</span></p>
                        </li>
                    </ul>
                </div>
            </div>}
        </>
    );
}

export default Payoption;
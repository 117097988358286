import { ConnectWallet } from "@thirdweb-dev/react";
import logo from '../img/logo.png';

const Header = () => {
    return(
        <div className="container px-4 lg:px-0">
            <div className="flex items-center justify-between py-6">
                <a href="/">
                    <img src={ logo } alt="logo" className="h-6 lg:h-16" />
                </a>
                <div className="flex items-center lg:gap-6">
                    <nav className="hidden lg:block">
                        <ul className="flex gap-6">
                            <li><a href="https://coinstrail.io" 
                                className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">
                                    Home</a></li>
                            <li><a href="https://bscscan.com/token/0x3645c60ce5679c950D629D0BCcfecf9d9Bd6E3B8#code" 
                                className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">
                                    Contract Verified</a></li>
                            <li><a href="/" 
                                className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">
                                    Contract Audit</a></li>
                            <li><a href="https://coinstrail.io/docs/whitepaper.pdf" 
                                className="text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75">
                                    White Paper</a></li>
                        </ul>
                    </nav>
                    <div className="flex items-center gap-2">
                        <ConnectWallet/>
                    </div>
                </div>
            </div>
            <div className="h-px bg-gradient-to-r from-black/0 to-white/20"></div>
        </div>
    );
}

export default Header;
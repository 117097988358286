const Description = ({CopyToClipboard, _affLink, setIsCopied}) => {
    return(<>
        <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
            <h2 className="mb-6 text-3xl leading-normal lg:text-left lg:text-5xl">
                🚀 Get in on the Action: <br />
                <span className="mx-3 bg-gradient-to-b from-[#F320D8] to-[#4B0491] bg-clip-text text-transparent">
                CTRL Token Public Sale</span><br />is Now Live! 🚀
            </h2>
            <p className="mb-2 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                Are you ready to supercharge your crypto portfolio? 
                Coinstrail presents the CTRL Token Public Sale - your golden ticket to a world of growth and opportunity.
                <br />📈 Unlock Unprecedented Growth: CTRL token's potential knows no bounds. Ride the crypto wave with a token designed for exponential growth.

                {/* <br />💼 Diverse Utility: From activating PTC packages to purchasing ad credits and shopping on our booming e-commerce platform, CTRL offers unparalleled utility.

                <br />💰 Maximize Earnings: Earn effortlessly through our dynamic Paid to Click platform and amplify your crypto holdings.

                <br />🌐 Global Reach: Join our thriving community and become part of the Coinstrail ecosystem that spans the globe. */}
                <br />
                <br />Don't miss out on this chance to secure your CTRL tokens and ride the crypto revolution to new heights. Get in early, and let CTRL pave the way for your crypto success. Join the Public Sale now! 🌟 #CTRLToken #CryptoGrowth
            </p>
            <p className="mb-8 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                    <a href="https://coinstrail.io/docs/whitepaper.pdf" target="_blank" 
                        className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr 
                        from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity 
                        duration-200 hover:opacity-75 lg:text-base" rel="noreferrer">
                        <span>Whitepaper</span>
                    </a>
                    <a href="/"
                        rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                        <span>Audit</span>
                    </a>
                    <a href="https://gopluslabs.io/token-security/56/0x3645c60ce5679c950D629D0BCcfecf9d9Bd6E3B8"
                        rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                        <span>Security</span>
                    </a>
                    <a href="https://bscscan.com/token/0x3645c60ce5679c950D629D0BCcfecf9d9Bd6E3B8"
                        rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                        <span>Address</span>
                    </a>
                </div>
            </p>
            <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                <br />
                Take advantage of our unique opportunity and earn from this public sales, Earn 5% instantly for every referrer when they use your affiliate link.
            
                <CopyToClipboard text={_affLink} onCopy={() => setIsCopied(true)}>
                    <span className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" > COPY AFFLIATE LINK </span>
                </CopyToClipboard>
            </div>
        </div>
    </>)
}

export default Description;